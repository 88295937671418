import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { getVersion as getDesktopAppVersion } from '@tauri-apps/api/app';
import { relaunch } from '@tauri-apps/plugin-process';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PRICING_PAGE_URL } from '../../../../shared/constants.platform';
import { getAuthedPathForDesktop } from '../../../../shared/foreground/auth.desktop';
import { globalState } from '../../../../shared/foreground/models';
import onLoggedOut from '../../../../shared/foreground/onLoggedOut.platform';
import { useProfileNameInitials } from '../../../../shared/foreground/stateHooks';
import { SubscriptionProduct } from '../../../../shared/types';
import { isDesktopApp } from '../../../../shared/utils/environment';
import getServerBaseUrl from '../../../../shared/utils/getServerBaseUrl.platform';
import { openURL } from '../../utils/openURL';
import { downloadAndInstallDesktopUpdate } from '../../utils/updates.desktop';
import useLocation from '../../utils/useLocation';
import Button from '../Button';
import ProfileIcon from '../icons/20StrokeProfile';
import SettingsMicroIcon from '../icons/SettingsMicroIcon';
import Tooltip from '../Tooltip';
import styles from './AccountDropdown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown';
import dropdownStyles from './Dropdown.module.css';

export default function AccountDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const desktopPendingUpdate = globalState(useCallback((state) => state.desktopPendingUpdate, []));
  const subscription = useMemo(() => profile?.subscription, [profile]);
  const canUpgrade = subscription?.product !== undefined && subscription.product !== SubscriptionProduct.Full;
  const nameInitials = useProfileNameInitials();
  const [desktopAppVersion, setDesktopAppVersion] = useState<string | undefined>();
  useEffect(() => {
    if (!isDesktopApp) {
      return;
    }
    (async () => {
      setDesktopAppVersion(await getDesktopAppVersion());
    })();
  }, []);

  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;

  const pathNamesForActiveState = ['/profile', '/preferences', '/integrations'];
  const isActive = Boolean(pathNamesForActiveState.find((activeStates) => pathName.startsWith(activeStates)));

  const options = useMemo(() => {
    const options: DropdownOption[] = [];
    options.push(
      {
        type: DropdownOptionType.Node,
        node: <Link to="/profile" className={styles.emailWrapper}>
          <p className={styles.profile}>Profile</p>
          <p className={styles.email}>{profile?.email}</p>
        </Link>,
      },
    );
    if (canUpgrade) {
      options.push(
        {
          type: DropdownOptionType.Item,
          name: 'Upgrade your account',
          shortcut: '->',
          onSelect: () => {
            openURL(PRICING_PAGE_URL, '_blank');
          },
        },
      );
    }
    options.push(
      {
        type: DropdownOptionType.Item,
        name: 'Preferences',
        onSelect: () => history.push('/preferences'),
      },
    );
    options.push(
      {
        type: DropdownOptionType.Item,
        name: 'Integrations',
        onSelect: () => history.push('/integrations'),
      },
    );
    options.push(
      {
        type: DropdownOptionType.Item,
        name: 'Product Emails',
        onSelect: () => history.push('/product-emails'),
      },
    );
    if (isDesktopApp && (desktopAppVersion || desktopPendingUpdate)) {
      options.push(getSeparatorOption());
      if (desktopAppVersion) {
        options.push(
          {
            type: DropdownOptionType.Title,
            name: `Reader Desktop v${desktopAppVersion}`,
          },
        );
      }
      if (desktopPendingUpdate) {
        const newVersion = desktopPendingUpdate.update.version;
        switch (desktopPendingUpdate.status) {
          case 'readyForDownload':
            options.push({
              type: DropdownOptionType.Item,
              name: `Install version ${newVersion}`,
              async onSelect() {
                  // this automatically kills & relaunches the app on windows, so no need to relaunch manually.
                await downloadAndInstallDesktopUpdate(desktopPendingUpdate.update);
              },
            });
            break;
          case 'readyForInstall':
            options.push({
              type: DropdownOptionType.Item,
              name: `Upgrade to version ${newVersion}`,
              onSelect() {
                relaunch();
              },
            });
            break;
          case 'downloading':
            options.push({
              type: DropdownOptionType.Title,
              name: 'Downloading update...',
            });
            break;
          case 'failed':
            options.push({
              type: DropdownOptionType.Title,
              name: 'Update failed.',
            });
            break;
        }
      }
    }
    options.push(getSeparatorOption());
    options.push(
      {
        type: DropdownOptionType.Item,
        name: 'Log out',
        onSelect: async() => {
          await onLoggedOut(false);
          const next = isDesktopApp
            ? encodeURIComponent(await getAuthedPathForDesktop())
            : '/read';
          window.location.href = `${getServerBaseUrl()}/logout?next=${next}`;
        },
      },
    );
    return options;
  }, [canUpgrade, desktopPendingUpdate, history, profile?.email, desktopAppVersion]);

  return (
    <div className={styles.dropdownWrapper}>
      {isActive && <div className={styles.activeIndicator} />}
      <Dropdown
        appendToDocumentBody
        isOpen={isOpen}
        options={options}
        setIsOpen={setIsOpen}
        side="right"
        contentAlignment="end"
        contentClassName={styles.dropdownContent}
        trigger={
          <Tooltip disabled={isOpen} content="View Account settings" placement="right">
            <DropdownMenu.Trigger asChild>
              <Button
                className={`${styles.triggerElement} ${triggerClassName} ${isActive ? styles.isActive : ''} ${isOpen ? dropdownStyles.isOpen : ''}`}
                variant="unstyled"
              >
                {nameInitials ?? <ProfileIcon />}
                <div className={styles.settingsIconWrapper}>
                  <SettingsMicroIcon />
                </div>
              </Button>
            </DropdownMenu.Trigger>
          </Tooltip>
        }
      />
    </div>
  );
}
