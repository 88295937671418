import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useLocation from '../../utils/useLocation';
import Button from '../Button';
import ViewsButtonIcon from '../icons/16StrokeViews';
import FeedIcon from '../icons/20StrokeFeedSm';
import TagIcon from '../icons/20StrokeTag';
import ViewsIcon from '../icons/20StrokeViews';
import Tooltip from '../Tooltip';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './ManageDropdown.module.css';

export default function ManageDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;

  const pathNamesForActiveState = ['/views', '/tags', '/feed/sources', '/feed/suggestions'];
  const isActive = pathNamesForActiveState.includes(pathName);

  const options = useMemo(() => [{
    type: DropdownOptionType.Item,
    name: 'Manage filtered views',
    icon: <ViewsIcon />,
    onSelect: () => history.push('/views'),
  }, {
    type: DropdownOptionType.Item,
    name: 'Manage tags',
    icon: <TagIcon />,
    onSelect: () => history.push('/tags'),
  }, {
    type: DropdownOptionType.Item,
    name: 'Manage feeds',
    icon: <FeedIcon />,
    onSelect: () => history.push('/feed/sources'),
  },
  ], [history]);

  return (
    <div className={styles.dropdownWrapper}>
      {isActive && <div className={styles.activeIndicator} />}
      <Dropdown
        appendToDocumentBody
        isOpen={isOpen}
        options={options}
        setIsOpen={setIsOpen}
        side="right"
        contentAlignment="end"
        contentClassName={styles.dropdownContent}
        trigger={
          <Tooltip disabled={isOpen} content="Manage views, tags, and feeds" placement="right">
            <DropdownMenu.Trigger asChild>
              <Button
                className={`${triggerClassName} ${styles.triggerElement} ${isOpen ? styles.isOpen : ''} ${isActive ? styles.isActive : ''}`}
                variant="default">
                <ViewsButtonIcon />
              </Button>
            </DropdownMenu.Trigger>
          </Tooltip>
        }
      />
    </div>
  );
}
