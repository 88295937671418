import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { Link, useHistory, useParams } from 'react-router-dom';

import { convertQueryToRxDBQuery } from '../../../shared/filters-compiler/convertQueryToRxDBQuery';
import { useCount } from '../../../shared/foreground/databaseHooks';
import { globalState } from '../../../shared/foreground/models';
import { useSavedFilteredViews } from '../../../shared/foreground/stateHooks';
import { useFilteredViews } from '../../../shared/foreground/stateHooks/filteredViews';
import { updateFilteredViewsOrder } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { updateFilterDirtyState } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import useCurrentFilteredView from '../../../shared/foreground/useCurrentFilteredView';
import getSplitByDefaultValue from '../../../shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import { FilteredView, FirstClassDocument, ShortcutId } from '../../../shared/types';
import { getCategoriesIconMap, getFilterViewAbbreviationNameOrEmoji } from '../../../shared/utils/filteredViews';
import useGoBackWithFallbackToRoot from '../hooks/useGoBackWithFallbackToRoot';
import { getFilteredViewPath } from '../utils/getFilteredViewPath';
import { useShortcutsMap } from '../utils/shortcuts';
import useLocation from '../utils/useLocation';
import AccountDropdown from './Dropdown/AccountDropdown';
import AddDropdown from './Dropdown/AddDropdown';
import ManageDropdown from './Dropdown/ManageDropdown';
import ArticlesIcon from './icons/ArticlesIcon';
import BooksIcon from './icons/BooksIcon';
import CircleClose from './icons/CircleClose';
import EmailsIcon from './icons/EmailsIcon';
import FeedIcon from './icons/FeedIconSidebar';
import HomeIcon from './icons/HomeIcon';
import LibraryIcon from './icons/LibraryIcon';
import LogoIcon from './icons/LogoIcon';
import PDFIcon from './icons/PDFIcon';
import SearchNavIcon from './icons/SearchNavIcon';
import StrokeGrab from './icons/StrokeGrabIcon';
import TwitterIcon from './icons/TwitterIcon';
import VideoIcon from './icons/VideoIcon';
import styles from './InboxSidebar.module.css';
import Tooltip from './Tooltip';

const Divider = ({ className = '' }: {className?: string;}) => <div className={`${styles.dividerWrapper} ${className}`}><div className={styles.divider} /></div>;

const itemIconMap = getCategoriesIconMap({
  articlesIcon: <ArticlesIcon key="article-icon" />,
  emailsIcon: <EmailsIcon key="email-icon" />,
  pdfsIcon: <PDFIcon key="pdf-icon" />,
  epubsIcon: <BooksIcon key="epub-icon" />,
  tweetsIcon: <TwitterIcon key="tweets-icon" />,
  videosIcon: <VideoIcon key="video-icon" />,
});

const MenuItem = ({
  children,
  classes = [],
  title,
  comingSoon = false,
  shortcut,
  url,
  isActive = false,
  innerRef,
  provided,
  isDragging = false,
  showGrab = false,
  badgeContent,
  style,
}: { children: React.ReactNode; title: string; comingSoon?: boolean; shortcut?: string | string[]; classes?: string[]; url?: string; isActive?: boolean; innerRef?: DraggableProvided['innerRef']; provided?: DraggableProvided; isDragging?: boolean;showGrab?: boolean; badgeContent?: string; style?: React.CSSProperties;}): JSX.Element => {
  const itemEl = useRef<HTMLLIElement>(null);

  const onClick = useCallback((e: React.MouseEvent) => {
    if (comingSoon) {
      e.preventDefault();
    }
  }, [comingSoon]);

  const badgeLeftPx = useMemo((): string => {
    if (!badgeContent) {
      return '';
    }

    if (badgeContent.length <= 2) {
      return '38px';
    }

    return '37px';
  }, [badgeContent]);

  return (
    <Tooltip content={`${title}${comingSoon ? ` - Coming soon` : ''}`} shortcut={shortcut} placement="right" offset={[0, -5]}>
      <li
        ref={itemEl}
        className={[styles.item, ...classes, isActive ? styles.itemActive : '', comingSoon ? styles.itemComingSoon : '', isDragging ? styles.isDragging : ''].join(' ')}
        style={style}
        {...(provided?.draggableProps ?? {})}
        {...(provided?.dragHandleProps ?? {})}
      >
        {typeof badgeContent !== 'undefined' && <div className={styles.badge} style={{ left: badgeLeftPx }}>{badgeContent}</div>}
        {showGrab && <div className={styles.grabIcon}>
          <StrokeGrab />
        </div>}
        <div className={styles.activeBorder} />
          <Link
            to={url || ''}
            onClick={onClick}
            ref={innerRef}
          >
            <div className={styles.iconWrapper}>
              {children as React.ReactElement}
            </div>
          </Link>
      </li>
    </Tooltip>
  );
};

const getBadgeContent = (count: number): string | undefined => {
  if (count === 0) {
    return undefined;
  }

  if (count > 1000) {
    return '1K+';
  }

  return `${count}`;
};

const useViewBadgeContent = (view: FilteredView) => {
  const documentLocations = useDocumentLocations();
  const shouldRun = Boolean(view.showCountBadge);
  const splitByValue = shouldRun && view.splitBy ? getSplitByDefaultValue(view.splitBy, documentLocations) ?? undefined : undefined;

  const { mangoQuery } = useMemo(() => convertQueryToRxDBQuery<FirstClassDocument>({
    query: view.query,
    splitBy: view.splitBy,
    splitByValue,
  }), [splitByValue, view.query, view.splitBy]);

  const [docsCount] = useCount('documents', mangoQuery ?? {}, { isEnabled: Boolean(shouldRun && mangoQuery) });

  if (!shouldRun) {
    return undefined;
  }

  return getBadgeContent(docsCount);
};

const FilteredViewItem = ({ view, isActive, innerRef, provided, isDragging = false, style, shortcut = '' }: {view: FilteredView; isActive: boolean; innerRef: DraggableProvided['innerRef']; provided: DraggableProvided; isDragging?: boolean; style?: React.CSSProperties; shortcut?: string | string[];}) => {
  const documentLocations = useDocumentLocations();
  const url = getFilteredViewPath(view, documentLocations);

  const name = useMemo(() => {
    if (itemIconMap[view.query]) {
      return itemIconMap[view.query];
    }

    return getFilterViewAbbreviationNameOrEmoji(view.name);
  }, [view.query, view.name]);

  const badgeContent = useViewBadgeContent(view);

  return (
    <MenuItem
      title={view.name || 'No name'}
      url={url}
      isActive={isActive}
      innerRef={innerRef}
      provided={provided}
      isDragging={isDragging}
      style={style}
      shortcut={shortcut}
      badgeContent={badgeContent}
      showGrab
    >
      <span>{name}</span>
    </MenuItem>
  );
};

const InboxSidebar = React.memo(function InboxSidebar ({ hasError = false }: { hasError?: boolean; }): JSX.Element {
  const pinnedViewsEl = useRef<HTMLUListElement>(null);
  const filteredViewsLoaded = Boolean(useFilteredViews());
  const [viewsScrolled, setViewsScrolled] = useState(false);
  const filterPreviousRoute = globalState(useCallback((state) => state.filterPreviousRoute, []));
  const history = useHistory();
  const location = useLocation();
  const { filterQuery } = useParams<{filterQuery: string;}>();
  const savedView = useCurrentFilteredView(filterQuery);
  const isUnsavedView = filteredViewsLoaded && Boolean(filterQuery) && !savedView;
  const isImportPage = location.pathname === '/import';
  const isDirtyPage = location.pathname === '/search' || isImportPage || location.pathname === '/account';
  const isDirtyState = isUnsavedView || isDirtyPage || hasError;
  const allFilteredViews = useSavedFilteredViews();
  const pinnedViews = allFilteredViews.filter((view) => view.isUnpinned !== true);
  const documentLocations = useDocumentLocations();
  const goBackWithFallbackToRoot = useGoBackWithFallbackToRoot();
  const shortcutsMap = useShortcutsMap();

  useEffect(() => {
    updateFilterDirtyState(isDirtyState);
    return () => {
      updateFilterDirtyState(false);
    };
  }, [isDirtyState]);
  // Hack to prevent the container to reset it's scroll position after re-render
  useEffect(() => {
    const element = pinnedViewsEl.current;

    if (!element) {
      return;
    }

    if (window.pinnedViewsScrollTop) {
      element.scrollTop = window.pinnedViewsScrollTop;
    }
  }, [pinnedViewsEl]);

  useEffect(() => {
    const element = pinnedViewsEl.current;

    if (!element) {
      return;
    }

    const onScroll = () => {
      window.pinnedViewsScrollTop = element.scrollTop;
      setViewsScrolled(element.scrollTop > 0);
    };

    element.addEventListener('scroll', onScroll, { passive: true });

    return () => element.removeEventListener('scroll', onScroll);
  }, [pinnedViewsEl]);

  const goBackAfterDirtyState = useCallback(() => {
    if (hasError) {
      window.location.reload();
      return;
    }

    if (isImportPage) {
      goBackWithFallbackToRoot();
    } else if (isDirtyPage) {
      history.push('/');
    } else {
      history.push(filterPreviousRoute);
    }
  }, [filterPreviousRoute, isImportPage, goBackWithFallbackToRoot, isDirtyPage, history, hasError]);

  const isInboxZero = globalState(useCallback((state) => state.isInboxZero, []));

  const getReorderedIds = (list: FilteredView[], startIndex: number, endIndex: number): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((view) => view.id);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedIds = getReorderedIds(
      pinnedViews,
      result.source.index,
      result.destination.index,
    );

    updateFilteredViewsOrder(reorderedIds.map((id, index) => ({ id, order: index })), { userInteraction: 'click' });
  };

  const dirtyStateSidebar = <button type="button" className={styles.dirtyStateSidebar} onClick={goBackAfterDirtyState}>
    <CircleClose />
  </button>;

  const defaultSidebar = <>
    <Link className={styles.logoWrapper} to="/">
      <LogoIcon />
    </Link>

    <Divider className={styles.firstDivider} />

    <nav className={styles.topNav}>
      <ul>
        <MenuItem
          isActive={location.pathname.startsWith('/home')}
          shortcut={shortcutsMap[ShortcutId.GoToHome]}
          title="Home"
          url="/home">
          <HomeIcon />
        </MenuItem>
        <MenuItem title="Library" url="/library" isActive={documentLocations.some((documentLocation) => location.pathname.startsWith(`/${documentLocation}`))} shortcut={shortcutsMap[ShortcutId.GoToLibrary]}>
          <LibraryIcon />
        </MenuItem>
        <MenuItem title="Feed" url="/feed" isActive={location.pathname.startsWith('/feed')} shortcut={shortcutsMap[ShortcutId.Feed]}>
          <FeedIcon />
        </MenuItem>
        <MenuItem title="Search" url="/search" isActive={location.pathname.startsWith('/search')} shortcut={shortcutsMap[ShortcutId.Search]}>
          <SearchNavIcon />
        </MenuItem>
      </ul>
    </nav>

    {pinnedViews.length > 0 && <Divider className={`${styles.secondDivider} ${viewsScrolled ? styles.fullWidth : ''}`} />}

    <nav className={styles.middleNav}>
      <ul ref={pinnedViewsEl}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) =>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {pinnedViews.map((view, index) =>
                  <Draggable key={view.id} draggableId={view.id} index={index}>
                    {(provided, snapshot) => {
                      const isActive = savedView?.id === view.id;
                      const shortcutNumber = index + 4;
                      const shortcut = shortcutNumber < 10 ? shortcutsMap[ShortcutId[`GoToPinnedView${index + 1}`]] : '';
                      return (
                        <FilteredViewItem
                          key={view.id}
                          view={view}
                          isActive={isActive}
                          innerRef={provided.innerRef}
                          provided={provided}
                          isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                          shortcut={shortcut}
                        />
                      );
                    }}
                  </Draggable>)}
                {provided.placeholder}
              </div>
            }
          </Droppable>
        </DragDropContext>
      </ul>
    </nav>

    <nav className={styles.bottomNav}>
      <ul>
        <Divider className={`${styles.middleDivider} ${viewsScrolled ? styles.fullWidth : ''}`} />
        <ManageDropdown triggerClassName={`${styles.manageDropdownButton}`} />
        <AddDropdown triggerClassName={`${styles.addDropdownButton}`} />
        <AccountDropdown triggerClassName={`${styles.appDropdownButton} ${styles.settingsButton}`} />
      </ul>
    </nav>
  </>;

  return <div className={`${styles.root} ${isInboxZero ? styles.sidebarInboxZero : ''}`}>
    <div className={styles.content}>
      {isDirtyState ? dirtyStateSidebar : defaultSidebar}
    </div>
  </div>;
});

export default InboxSidebar;
