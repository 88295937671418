import React from 'react';

import Icon from './Icon';

export default function SettingsMicroIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="Settings">
<svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.99995 0.153809C5.38232 0.153809 5.69229 0.463778 5.69229 0.846144V1.81165C6.06781 1.89279 6.43178 2.0408 6.76517 2.25566L7.4494 1.57143C7.71977 1.30106 8.15813 1.30106 8.42851 1.57143C8.69888 1.84181 8.69888 2.28017 8.42851 2.55054L7.74436 3.23469C7.95931 3.56808 8.10739 3.93207 8.18859 4.30762H9.15418C9.53655 4.30762 9.84652 4.61759 9.84652 4.99995C9.84652 5.38232 9.53655 5.69229 9.15418 5.69229H8.18868C8.10752 6.0679 7.95946 6.43195 7.74452 6.76541L8.42851 7.4494C8.69888 7.71977 8.69888 8.15813 8.42851 8.42851C8.15813 8.69888 7.71977 8.69888 7.4494 8.42851L6.76541 7.74452C6.43195 7.95946 6.0679 8.10752 5.69229 8.18868V9.15418C5.69229 9.53655 5.38232 9.84652 4.99995 9.84652C4.61759 9.84652 4.30762 9.53655 4.30762 9.15418V8.18859C3.93196 8.10736 3.56786 7.95922 3.23439 7.74417L2.55006 8.42851C2.27968 8.69888 1.84132 8.69888 1.57094 8.42851C1.30057 8.15813 1.30057 7.71977 1.57094 7.4494L2.25547 6.76487C2.04071 6.43156 1.89277 6.0677 1.81165 5.69229H0.846144C0.463778 5.69229 0.153809 5.38232 0.153809 4.99995C0.153809 4.61759 0.463778 4.30762 0.846144 4.30762H1.81174C1.8929 3.93227 2.04086 3.56847 2.25562 3.23522L1.57094 2.55054C1.30057 2.28017 1.30057 1.84181 1.57094 1.57143C1.84132 1.30106 2.27968 1.30106 2.55006 1.57143L3.23463 2.25601C3.56804 2.04104 3.93205 1.89295 4.30762 1.81174V0.846144C4.30762 0.463778 4.61759 0.153809 4.99995 0.153809ZM6.3279 3.67243C5.59462 2.93914 4.40572 2.93914 3.67243 3.67243C2.93914 4.40572 2.93914 5.59462 3.67243 6.3279C4.40572 7.06119 5.59462 7.06119 6.3279 6.3279C7.06119 5.59462 7.06119 4.40572 6.3279 3.67243Z" fill="currentColor" />
</svg>
  </Icon>;
}
