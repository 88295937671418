import React from 'react';

import Icon from './Icon';

export default function NavAddIcon({ className = '', text = 'Nav' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4 0.999023V4.66569C16.4 6.28636 17.7127 7.59902 19.3333 7.59902L22.9899 7.59902C23 11.1065 22.9899 14.6082 23 18.1101C23 20.81 20.811 22.999 18.1111 22.999H5.88889C3.189 22.999 1 20.81 1 18.1101L1 5.88791C1 3.18802 3.189 0.999024 5.88889 0.999024L16.4 0.999023Z" fill="#376EF2" />
      <path d="M15.9998 12.9985C16.552 12.9984 16.9996 12.5506 16.9995 11.9983C16.9994 11.446 16.5516 10.9984 15.9993 10.9985L12.9995 10.9993V7.99854C12.9995 7.44625 12.5518 6.99854 11.9995 6.99854C11.4472 6.99854 10.9995 7.44625 10.9995 7.99854V10.9998L7.99927 11.0005C7.44698 11.0006 6.99938 11.4484 6.99951 12.0007C6.99965 12.553 7.44747 13.0006 7.99976 13.0005L10.9995 12.9998V15.9985C10.9995 16.5508 11.4472 16.9985 11.9995 16.9985C12.5518 16.9985 12.9995 16.5508 12.9995 15.9985V12.9993L15.9998 12.9985Z" fill="white" />
      <path d="M18 7.99902H23V7.66078C23 7.13035 22.7893 6.62164 22.4142 6.24657L17.7525 1.58481C17.3774 1.20974 16.8687 0.999023 16.3382 0.999023H16V5.99902C16 7.10359 16.8954 7.99902 18 7.99902Z" fill="#84ACF1" />
    </svg>
  </Icon>;
}
