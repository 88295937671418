import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { openFeedsSubMenu, openSaveDocSubMenu } from '../../../../shared/foreground/cmdPalette';
import { ShortcutId } from '../../../../shared/types';
import { useHotKeysPreventDefault } from '../../hooks/hooks';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import { FileUploadContext } from '../FileDropzone';
import NavAddIconIcon from '../icons/NavAddIcon';
import Tooltip from '../Tooltip';
import styles from './AddDropdown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';

export default function AddDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const { openFileDialog } = useContext(FileUploadContext);
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();

  useHotKeysPreventDefault(
    shortcutsMap[ShortcutId.UploadFile],
    useCallback(() => openFileDialog(), [openFileDialog]),
    { description: 'Upload file' },
  );

  const options = useMemo(() => [{
    type: DropdownOptionType.Item,
    name: 'Add URL',
    shortcut: shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette],
    onSelect: openSaveDocSubMenu,
  }, {
    type: DropdownOptionType.Item,
    name: 'Upload file',
    shortcut: shortcutsMap[ShortcutId.UploadFile],
    onSelect: openFileDialog,
  },
    getSeparatorOption(),
  {
    type: DropdownOptionType.Item,
    name: 'Subscribe to RSS feed',
    shortcut: shortcutsMap[ShortcutId.ManageFeedSubscriptions],
    onSelect: openFeedsSubMenu,
  }, {
    type: DropdownOptionType.Item,
    name: 'Subscribe to Twitter List',
    shortcut: shortcutsMap[ShortcutId.ManageFeedSubscriptions],
    onSelect: openFeedsSubMenu,
  },
    getSeparatorOption(),
  {
    type: DropdownOptionType.Item,
    name: 'More import options...',
    onSelect: () => history.push('/import'),
  },
  ], [openFileDialog, history, shortcutsMap]);

  return (
    <Dropdown
      appendToDocumentBody
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      side="right"
      contentAlignment="end"
      contentClassName={styles.dropdownContent}
      trigger={
        <Tooltip disabled={isOpen} content="Add documents, feed sources, and more" placement="right">
          <DropdownMenu.Trigger asChild>
            <Button
              className={`${triggerClassName} ${styles.triggerElement} ${isOpen ? styles.isOpen : ''}`}
              variant="unstyled">
              <NavAddIconIcon />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
      }
    />
  );
}
