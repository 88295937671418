import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { globalState } from '../../../shared/foreground/models';

const useGoBackWithFallbackToRoot = (): () => void => {
  const routeStack = globalState(useCallback((state) => state.routeStack, []));
  const history = useHistory();

  return useCallback(() => {
    const prevRoute = routeStack[routeStack.length - 2];
    history.push(prevRoute || '/');
  }, [routeStack, history]);
};

export default useGoBackWithFallbackToRoot;
