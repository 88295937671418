import React from 'react';

import Icon from './Icon';

export default function ProfileIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="Profile">
<svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4 7.24992C4.41421 7.24992 4.75 7.5857 4.75 7.99992V10.4999C4.75 10.9141 4.41421 11.2499 4 11.2499C3.58579 11.2499 3.25 10.9141 3.25 10.4999V7.99992C3.25 7.5857 3.58579 7.24992 4 7.24992Z" fill="black" />
<path fillRule="evenodd" clipRule="evenodd" d="M16 7.24992C16.4142 7.24992 16.75 7.5857 16.75 7.99992V10.4999C16.75 10.9141 16.4142 11.2499 16 11.2499C15.5858 11.2499 15.25 10.9141 15.25 10.4999V7.99992C15.25 7.5857 15.5858 7.24992 16 7.24992Z" fill="black" />
<path fillRule="evenodd" clipRule="evenodd" d="M10.1626 4.26778C10.567 4.35763 10.8219 4.75826 10.7321 5.16261L9.00251 12.9457C8.96782 13.1018 9.08662 13.2499 9.24656 13.2499H11.9999C12.4142 13.2499 12.7499 13.5857 12.7499 13.9999C12.7499 14.4141 12.4142 14.7499 11.9999 14.7499H9.24656C8.127 14.7499 7.29537 13.7132 7.53823 12.6203L9.2678 4.83722C9.35766 4.43287 9.75829 4.17792 10.1626 4.26778Z" fill="black" />
</svg>
  </Icon>;
}
