import React from 'react';

import Icon from './Icon';

export default function ViewsIcon({ className = '', text = 'Views' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.60257 0.36402C9.84579 0.211993 10.1544 0.211993 10.3976 0.36402L18.3976 5.36454C18.6169 5.50161 18.7501 5.74197 18.7501 6.00056C18.7501 6.25915 18.6169 6.4995 18.3976 6.63654L10.3976 11.636C10.1544 11.788 9.84582 11.788 9.60263 11.636L1.60263 6.63654C1.38334 6.4995 1.25011 6.25915 1.2501 6.00056C1.25009 5.74197 1.38329 5.50161 1.60257 5.36454L9.60257 0.36402ZM3.4152 6.00046L10.0001 10.1156L16.585 6.00046L10.0001 1.88446L3.4152 6.00046Z" fill="currentColor" />
      <path d="M1.3641 9.6025C1.58363 9.25125 2.04635 9.14447 2.3976 9.364L10.0001 14.1156L17.6026 9.364C17.9539 9.14447 18.4166 9.25125 18.6361 9.6025C18.8556 9.95375 18.7489 10.4165 18.3976 10.636L10.3976 15.636C10.1544 15.788 9.8458 15.788 9.6026 15.636L1.6026 10.636C1.25135 10.4165 1.14457 9.95375 1.3641 9.6025Z" fill="currentColor" />
      <path d="M1.3641 13.6025C1.58363 13.2512 2.04635 13.1445 2.3976 13.364L10.0001 18.1156L17.6026 13.364C17.9539 13.1445 18.4166 13.2512 18.6361 13.6025C18.8556 13.9538 18.7489 14.4165 18.3976 14.636L10.3976 19.636C10.1544 19.788 9.8458 19.788 9.6026 19.636L1.6026 14.636C1.25135 14.4165 1.14457 13.9538 1.3641 13.6025Z" fill="currentColor" />
    </svg>
  </Icon>;
}
